<template>
  <div >
                            <div class="nk-block-head">
                                <div class="nk-block-head-content">
                                    <h2 class="nk-block-title fw-normal">File Access History</h2>
                                    <div class="nk-block-des">
                                         <!-- <div href="#" class="link link-danger float-right">Clear log</div> -->
                                        <p>File Access History. <span class="text-soft"></span></p>
                                    </div>
                                </div>
                            </div><!-- .nk-block-head -->
                            <div class="nk-block">
                                <div class="card card-bordered">
                                    <table v-if="activities" class="table table-ulogs">
                                        <thead class="thead-light">
                                            <tr>
                                                <th class="tb-col-os"><span class="overline-title">File Name <span class="d-sm-none">/ IP</span></span></th>
                                                <th class="tb-col-ip"><span class="overline-title">Action</span></th>
                                                <th class="tb-col-time"><span class="overline-title">Time</span></th>
                                                 <th class="tb-col-ip"><span class="sub-text">User</span></th>
                                                <!-- <th class="tb-col-action"><span class="overline-title">&nbsp;</span></th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="activity in activities" v-bind:key="activity.id">
                                                <td class="tb-col-os">{{activity.fileName}}</td>
                                                <td class="tb-col-ip"><span class="sub-text">{{activity.action}}</span></td>
                                                <td class="tb-col-time"><span class="sub-text">{{$moment(activity.createdDate).fromNow()}}</span></td>
                                                 <td class="tb-col-ip"><span class="sub-text">{{activity.userName}}</span></td>
                                                <!-- <td class="tb-col-action"></td> -->
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div><!-- .card -->
                            </div><!-- .nk-block -->
                        </div>
</template>

<script>
export default {
data() {
    return {
      activities: null,
    }
  },
  created() {
    this.getView()
    this.getFileHistory()
  },
  methods: {
    getView() {
            this.$http.get('/ViewAccess/crmfilehistory')
            .then(() => {
            })
            .catch(() => {
            })
        },
    getFileHistory () {
      this.$http.get('/system/Get_FileHistory')
        .then((response) => {
          this.activities = response.data
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
  }
}
</script>

<style>

</style>